<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="医院智慧人事" @moTitltFn="navPanel"></MoTitle>
    <div class="moXueYuan">
      <!-- 头部 -->
      <div class="moTitle center">
          <h1 style="color:#fff;font-size: 1.5rem;margin-bottom:20px">医院智慧人事</h1>
          <button class="showBtn" @click="$router.push('/moShow')">立即预约演示</button>
      </div>

      <div class="contentBgColor center">
        <h2 class="h2Desc">应用框架</h2>
        <span class="spanDesc"></span>
        <img src="@/assets/solute/samartYy/gnt.png" alt="" style="width:100%;margin:30px 0">
      </div>

      <div class="contentBgColor center">
        <h2 class="h2Desc">业务痛点</h2>
        <span class="spanDesc"></span>
        <div class="moValueContent">
          <div v-for="(item,index) in yetdData" :key="index" class="moValueItem">
            <div class="moValueTitle">{{item.title}}</div>
            <div class="moValueLine"></div>
            <div class="moValueText">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="contentBgColor center">
        <h2 class="h2Desc">解决方案</h2>
        <span class="spanDesc"></span>
        <div class="moValueContent">
          <div v-for="(item,index) in soliteData" :key="index" class="moValueItem">
            <div class="moValueTitle">{{item.title}}</div>
            <div class="moValueLine"></div>
            <div class="moValueText">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="contentBgColor center">
          <h2 class="h2Desc">应用案例</h2>
          <span class="spanDesc"></span>
          <div class="moCase">
            <MoSwiper :list="caseData"></MoSwiper>
          </div>
      </div>
    </div>
    <MoBottom></MoBottom>
      <MoFoot></MoFoot>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
import MoSwiper from '@/components/moswiper'
export default {
  name: 'moXueYuan',
  components: {
    MoTitle,
    MoSwiper
  },
  data () {
    return {
      isScroll: false,
      yetdData: [
        { title: '管理体系缺乏完善', content: '员工招聘、管理、培训、考核等是人力资源管理的重要工作内容，而且考勤、薪酬等工作量较大、医院人事管理体制复杂，使很多医院人力资源管理工作都存在着“浅尝辄止”的现象，制度体系也十分不健全，人事部的一些工作往往都存在着流于表面、过分注重形式的问题。' },
        { title: '人才管理意识淡薄', content: '人才是医院的核心竞争力，医疗人才的充沛性是确保医院健康发展的根本性保障，但是很多医院在人力资源管理上还是倾向于医院基础业务工作的开展，对于员工入职后的培训、考核工作未进行合理的规划，还有很多公立医院在管理上仍然将重心放在了对学历及职称的考核上。' },
        { title: '人力资源信息化水平不高', content: '随着信息技术的快速发展，目前在各行各业中得以广泛应用，但在传统的管理方式下，医院的人力管理过程中的问题还是不够高效，人员相关信息分别在人事部、科教部、医务部、业务科室等不同部门，部门间信息标准和口径不统一，难以动态更新，缺乏数据共享，无法及时、准确掌握医院岗位、人员信息。' },
        { title: '领导决策无数据支持', content: '随着医院业务发展需要，内部存在较多的业务系统，如HIS系统、科研系统、OA等，每套系统都有各自的信息采集标准，导致系统间数据交互难度较大，如何实现数据联动成为亟待解决的问题。另外医院的人力数据多而分散，同时也缺少全面的分析模型，导致领导在作出决策时缺少准确、直观的数据依据，也影响了人力资源的规划和人才配置。' }

      ],
      soliteData: [
        { title: '一体化数据，搭建医院集约化人力平台', content: '覆盖职工全生命周期管理，所有成长记录均有迹可循，对接HIS、手麻系统、OA、科研系统、财务系统、钉钉、企业微信等外部平台，形成“人事+财务+业务”一体化管理，提供一站式人力资源解决方案，规范化人事流程。' },
        { title: '全渠道招聘覆盖，抢占优质人才资源', content: '招聘全过程、全流程智能化管理，覆盖主流招聘渠道，岗位更新、简历筛选一站式管理，人才盘点与人才激活，精准筛选高潜人才。+财务+业务”一体化管理，提供一站式人力资源解决方案，规范化人事流程。' },
        { title: '自动化流程，医院管理更敏捷', content: '组织架构灵活调整，赋能业务快速迭代。将人事调配、合同签订、请假排班、多维考核等各项人事业务由传统质处理方式变为流畅的网上审批，不仅规范了业务流程，而且大大提高了工作质量和效率。' },
        { title: '个性化管理，打造员工自由体验', content: '职工基于自助平台在线查看个人信息、工资条、业务进展、考核信息等，办理请假、职称申报等业务，有效提高管理参与度及满意度，促进信息完善与更新。界面操作简洁友好，在每个细节根植互联网氛围，落地医院人性化管理，激发员工活跃性与业务能力。' }

      ],
      caseData: [
        { src: require('@/assets/solute/samartYy/tjyy.png'), title: '同济医院', p: '同济医院人力资源管理与服务平台的建设，是在“智慧医院”建设的整体规划下，采取分期建设、持续优化的策略，于2020年开始建设，已完成机构管理、职工信息库、职称评审、岗位聘任、博士后管理、高层次人才管理、出国管理等模块，建立了人事信息化标准，沉淀了大量职工数据，梳理和优化了人事管理和服务流程，提升了人事管理的效率和服务水平。易普行凭借创新的服务理念、先进的产品技术、丰富的业务积累、领先的综合实力和优质的服务体系，助力华中科技大学同济医学院附属同济医院人力资源管理与服务一体化平台项目建设成功落地。' },
        { src: require('@/assets/solute/samartYy/whdxrmyy.png'), title: '武汉大学人民医院', p: '湖北省人民医院（武汉大学人民医院）人力资源管理系统，于2022年开始建设和使用，逐步实现职工入院、在院、离院全生命周期的业务管理与服务。易普行人力资源管理系统产品设计具备一定的前瞻性，产品功能完备、配置灵活更符合医院人力资源现阶段管理转型升级的需要，最终以绝对的优势在众多竞争公司中脱颖而出。人事管理与服务基本已覆盖首义院区、光谷院区事业编人员的业务，促使医院人事管理信息化业务逐步步入正轨，将医院日常的人事管理业务流程化、规范化、秩序化。' },
        { src: require('@/assets/solute/samartYy/tzszxyy.png'), title: '台州市中心医院', p: '台州市中心医院人力资源管理与服务平台的建设，是在“智慧医院”建设的整体规划下，医院人力资源管理与服务数智化转型的新模式。人力资源管理与服务平台的建设，将梳理和整合人事信息、人才引进、考勤排班、职称评审、离退等以职工管理与服务密切相关的业务，按“一数一源一责”的原则明确数据采集和维护机制，按部门职责梳理业务管理流程，实现业务对接与协同、以职工为中心提供更加丰富、便捷的服务功能和应用，实现职工数据、业务管理与职工服务三者之间的有机统一、相辅相成和共建共享，不断发挥“数据”在业务流程改善、服务体验提升和决策支持能力的价值。' }
      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/solution/smartYy')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/solute/smartInstitute/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.moXueYuan{
  padding-top: 2.75rem;
}
.moCase{
  width: 100%;
  margin-top: 1.875rem;
}
.designIdea{
  margin: 20px 0;
  font-size: .9375rem;
line-height: 1.5rem;

}
.knowMoreBtn{
  width: 120px;
  height: 36px;
  background: #FFFFFF;
  border-radius: 30px;
  border: 1px solid rgba(211,218,228,0.5);
  color: #444444;
  font-size: 14px;
}

.moValueTopText{
  text-align:center;
  margin-top:15px;
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  line-height: 22px;
}
.moValueContent{
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.moValueItem{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid rgba(220,223,230,.5);
  box-sizing: border-box;
}
.moValueTitle{
  font-size: 16px;
  font-weight: 600;
  color: #222222;
  line-height: 24px;
}
.moValueLine{
  width: 20px;
  height: 3px;
  background: #D70C0C;
  margin: 8px 0;
}
.moValueText{
  font-size: 14px;
  font-weight: 400;
  color: #444444;
  line-height: 22px;
}
</style>
